<template>
    <VVVLayoutNew>
        <template #page-title>
            Home
        </template>
        <MDBAlert v-if="isUser && userDetail.isNewUser && revenueAlert" color="warning" static class="mb-2">
            <div class="alert-container">
                <vue-feather type="alert-circle" :stroke-width="2.5" />
                <p class="mb-0 alert-message">
                    Welcome, <strong style="text-transform: capitalize;">{{ userDetail.firstName }}</strong>! Your first
                    earnings
                    report will be live in about 30-days. <a href="//CreatorShield.com/support" target="_blank"
                        rel="noopener noreferrer">See the earnings
                        schedule</a>.
                </p>
                <vue-feather type="x" :stroke-width="2.5" size="18" class="ms-auto" @click="revenueAlert = false"
                    style="cursor: pointer;" />
            </div>
        </MDBAlert>
        <MDBAlert v-if="googleSheetAlert" static class="mb-4" style="background: #223698; color: white;">
            <div class="alert-container">
                <vue-feather type="alert-circle" :stroke-width="2.5" />
                <p class="mb-0 alert-message">
                    Welcome to our refreshed dashboard.
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfpkfJTFTKfC_gXKUhFUdjUuTXBuE4BZKR5TZg5jw07BRQ96g/viewform"
                        target="_blank" rel="noopener noreferrer" class="text-white text-decoration-underline">
                        Send us feedback on how to improve.
                    </a>
                </p>
                <vue-feather type="x" :stroke-width="2.5" size="18" class="ms-auto" @click="googleSheetAlert = false"
                    style="cursor: pointer;" />
            </div>
        </MDBAlert>
        <div class="mb-3 mb-md-4 gap-3 metrics-container">
            <MDBCard class="shadow rounded-5">
                <MDBCardHeader tag="h5" class="fw-bold">
                    Issues
                </MDBCardHeader>
                <MDBCardBody class="p-0 pb-1" style="min-height: 300px;"
                    :style="isWidgetLoading ? { display: 'grid', placeContent: 'center' } : {}">
                    <div class="image-container image-container-big" v-if="isWidgetLoading">
                        <div class="loader"></div>
                        <img src="@/assets/VVV/vvv-loader.png" alt="Loading image">
                    </div>
                    <MDBListGroup v-else>
                        <template v-for="counter in issueList" :key="counter.id">
                            <router-link :to="'/claims-list?claimStatus=' + counter.id" custom
                                v-slot="{ href, navigate }">
                                <MDBListGroupItem tag="a" :href="href" @click.stop="navigate"
                                    style="display:flex; justify-content: space-between; align-items: center; border: 0"
                                    title="View List">
                                    <span>{{ counter.name }}</span>
                                    <MDBBadge color="primary">{{ getCount(counter.id) }}</MDBBadge>
                                </MDBListGroupItem>
                            </router-link>
                        </template>
                    </MDBListGroup>
                </MDBCardBody>
            </MDBCard>
            <MDBCard class="shadow rounded-5">
                <MDBCardHeader tag="h5" class="fw-bold">
                    Your overview
                </MDBCardHeader>
                <MDBCardBody class="p-0 pb-1"
                    :style="isWidgetLoading ? { display: 'grid', placeContent: 'center' } : {}">
                    <div class="image-container image-container-big my-3" v-if="isWidgetLoading">
                        <div class="loader"></div>
                        <img src="@/assets/VVV/vvv-loader.png" alt="Loading image">
                    </div>
                    <MDBListGroup v-else>
                        <MDBListGroupItem
                            style="display:flex; justify-content: space-between; align-items: center; border: 0">
                            <span>Total Revenue Generated</span>
                            <MDBBadge color="primary">$ {{ totalRevenue }}</MDBBadge>
                        </MDBListGroupItem>
                        <MDBListGroupItem
                            style="display:flex; justify-content: space-between; align-items: center; border: 0">
                            <span>Revenues Generated Last Month</span>
                            <MDBBadge color="primary">$ {{ revenueLastMonth }}</MDBBadge>
                        </MDBListGroupItem>
                        <MDBListGroupItem
                            style="display:flex; justify-content: space-between; align-items: center; border: 0">
                            <span>Total Active Claims</span>
                            <MDBBadge color="primary">{{ activeClaimsCount }}</MDBBadge>
                        </MDBListGroupItem>
                        <MDBListGroupItem
                            style="display:flex; justify-content: space-between; align-items: center; border: 0">
                            <span>Pending Claims</span>
                            <MDBBadge color="primary">{{ pendingClaims }}</MDBBadge>
                        </MDBListGroupItem>
                        <MDBListGroupItem
                            style="display:flex; justify-content: space-between; align-items: center; border: 0">
                            <span>New Claims Approval Rate (30 days)</span>
                            <MDBBadge color="primary">{{ approvalRatePercentage }} %</MDBBadge>
                        </MDBListGroupItem>
                    </MDBListGroup>
                </MDBCardBody>
            </MDBCard>
            <MDBCard class="shadow rounded-5">
                <MDBCardHeader tag="h5" class="fw-bold">
                    Latest Tutorial
                </MDBCardHeader>
                <MDBCardBody class="p-0 py-3" v-if="!isWidgetLoading && !latestTutorial"
                    style="display: grid;place-content: center;">
                    Uploading Soon!
                </MDBCardBody>
                <MDBCardBody v-else :style="isWidgetLoading ? { display: 'grid', placeContent: 'center' } : {}">
                    <div class="image-container image-container-big" v-if="isWidgetLoading">
                        <div class="loader"></div>
                        <img src="@/assets/VVV/vvv-loader.png" alt="Loading image">
                    </div>
                    <template v-else>
                        <template v-if="latestTutorial.link">
                            <iframe width="100%" height="250" :src="getYoutubeVideoIframe(latestTutorial.link)"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen></iframe>
                            <div class="pt-2">
                                <p class="text-left fw-bold mb-2" style="font-size: 15px;">
                                    {{ latestTutorial.title }}
                                </p>
                                <p class="text-left" style="font-size: 14px;">
                                    {{ latestTutorial.viewDescription }}
                                </p>
                                <MDBBtn class="btn-primary fw-bold" size="sm" @click="toTutorials">
                                    View All Tutorials
                                    <MDBIcon icon="arrow-right" class="fas fa-fw" />
                                </MDBBtn>
                            </div>
                        </template>
                    </template>
                </MDBCardBody>
            </MDBCard>
        </div>
        <MDBCard class="shadow rounded-5">
            <MDBCardHeader tag="h6"
                class="text-left fw-bold justify-content-between align-items-center bg-white d-block d-md-flex border-0">
                Recently Added Claims
                <MDBBtn class="btn-primary fw-bold mt-md-0 mt-3" size="sm" @click="toClaimsList">
                    View Full List
                    <MDBIcon icon="arrow-right" class="fas fa-fw" />
                </MDBBtn>
            </MDBCardHeader>
            <MDBCardBody class="overflow-auto justify-content-start p-0">
                <MDBTable hover class="align-middle">
                    <thead class="table-light" style="z-index: 0;">
                        <tr>
                            <th scope="col" class="text-uppercase align-middle text-left" v-if="isAdminOrSuperAdmin">
                                Distributor Name
                            </th>
                            <th v-for="(header, i) in tableHeaders" :key="i" scope="col"
                                class=" align-middle text-left text-uppercase">
                                {{ header }}
                            </th>
                        </tr>
                    </thead>
                    <tbody class="overflow-auto">
                        <template v-if="isLoading">
                            <tr>
                                <td :colspan="isAdminOrSuperAdmin ? '7' : '6'" class="text-center">
                                    <div class="image-container image-container-big d-flex justify-content-center align-items-center">
                                        <div class="loader"></div>
                                        <img src="@/assets/VVV/vvv-loader.png" alt="Loading image">
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <template v-if="contentsList.length === 0">
                                <tr>
                                    <td :colspan="isAdminOrSuperAdmin ? '7' : '6'" class="text-center">No claims found.
                                    </td>
                                </tr>
                            </template>
                            <template v-else v-for="(contents, k) in contentsList" :key="k">
                                <tr>
                                    <td v-if="isAdminOrSuperAdmin" class="text-truncate" style="max-width: 180px;">
                                        {{ contents.userId ? contents.userFirstName + ' ' + contents.userLastName : '-'
                                        }}
                                    </td>
                                    <td class="text-truncate" style="max-width: 200px;">
                                        <img v-if="contents.thumbnail" :src="contents.thumbnail" alt="thumbnail"
                                            style="width: 30px; height: 30px" class="rounded-circle me-2" />
                                        <img v-else src="@/assets/user-default.png" alt="thumbnail"
                                            style="width: 30px; height: 30px" class="rounded-circle me-2" />
                                        <span>
                                            {{ contents.youtubeChannel }}
                                        </span>
                                    </td>
                                    <td class="text-truncate" style="max-width: 180px;"><a :href="contents.youtubeUrl"
                                            target="_blank" class="text-left">{{ contents.youtubeUrl }}</a>
                                    </td>
                                    <td>{{ contents.timeStamp }}</td>
                                    <td class="text-truncate" style="max-width: 180px;"><a :href="contents.dropboxLink"
                                            target="_blank" class="text-left">{{ contents.dropboxLink }}</a>
                                    </td>
                                    <td>{{ parseDateLong(contents.dateAdded) }}</td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </MDBTable>
            </MDBCardBody>
        </MDBCard>
    </VVVLayoutNew>
</template>

<script setup>
import { MDBCard, MDBCardBody, MDBCardHeader, MDBTable, MDBBtn, MDBIcon, MDBAlert, MDBBadge, MDBListGroup, MDBListGroupItem } from "mdb-vue-ui-kit";
import { onMounted, ref, defineExpose, computed } from "vue";
import { GetContentsClaimStatusList } from "@/services/Contents/GetContentsClaimStatusList";
import VVVLayoutNew from "@/views/VVVNew/VVVLayoutNew.vue";
import { useUserDetailStore } from "@/store/userDetailStore";
import { storeToRefs } from "pinia";
import { parseDateLong } from "@/helpers/parseDate";
import { useRouter } from "vue-router";
import { useTitle, useStorage, useEventListener } from "@vueuse/core";
import { GetContentsList } from "@/services/Contents/GetContentsList";
import { GetContentsClaimStatusOverview } from "@/services/Contents/GetContentsClaimStatusOverview";
import { GetRevenueWidgets } from "@/services/Revenue/GetRevenueWidget";
import { GetVideoTutorialList } from "@/services/Tutorials/GetTutorialList";
import { extractVideoId } from "@/helpers/youtubeScraper";
import VueFeather from 'vue-feather';

const loading = ref(false);
const issueList = ref([
    // { id: 1, name: "Active Claims", icon: "check-circle", color: '#28a745', badgeColor: 'warning', message: 'Hooray, new claims approved!' },
    { id: 2, name: "Issues", icon: "times-circle", color: '#dc3545', badgeColor: 'danger', message: 'Take action as soon as possible' },
    { id: 3, name: "Duplicate Claims", icon: "copy", color: '#fd7e14', badgeColor: '', message: '' },
    { id: 4, name: "Resolved Issues", icon: "tools", color: '#007bff', badgeColor: '', message: '' },
    { id: 5, name: "Submitted Claims", icon: "file-upload", color: '#6c757d', badgeColor: '', message: '' },
    { id: 6, name: "For Further Review", icon: "exclamation-triangle", color: '#d5d500', badgeColor: '', message: '' },
    // { id: 7, name: "Awaiting for 100 views", icon: "eye", color: '#ffc107', badgeColor: '', message: '' }, // Remove temporarily
    { id: 8, name: "Awaiting for 1 view", icon: "hourglass-half", color: '#ffc107', badgeColor: '', message: '' }
]);
const response = ref([]);

const userDetailStore = useUserDetailStore();
const { userDetail, isUser, isAdmin, isSuperAdmin } = storeToRefs(userDetailStore);
const isAdminOrSuperAdmin = computed(() => isAdmin.value || isSuperAdmin.value);

const tableHeaders = computed(() => {
    const commonHeaders = [
        "Channel Name",
        "Youtube URL",
        "Timestamp",
        "Date Added",
    ];

    const userSpecificHeader = isUser.value ? "Clip Used" : "Clip URL";

    return [...commonHeaders.slice(0, -1), userSpecificHeader, commonHeaders[commonHeaders.length - 1]];
});

const router = useRouter();
const toClaimsList = () => {
    router.push("/claims-list");
};
const toTutorials = () => {
    router.push(`/tutorials`);
};

const isLoading = ref(false);
const contentsList = ref([]);
const contentsListPagination = ref({});
const totalRevenue = ref(null);
const revenueLastMonth = ref(null);
const latestTutorial = ref();
const isWidgetLoading = ref(true);
const claimStatusOverview = ref();

onMounted(async () => {
    useTitle("Dashboard | Very Viral Videos");
    await loadBreakdown();
    await fetchContentsList()
    isWidgetLoading.value = true;
    totalRevenue.value = await getTotalRevenue();
    revenueLastMonth.value = await getRevenueLastMonth();
    claimStatusOverview.value = await getClaimStatusOverview();
    latestTutorial.value = await getVideoTutorialList()
    isWidgetLoading.value = false;
});


const fetchContentsList = async () => {
    isLoading.value = true;
    try {
        const contentRes = await GetContentsList({ pageNumber: 1, rowsPerPage: 5 });
        contentsList.value = contentRes.data;
        contentsListPagination.value = contentRes.pagination;
    } catch (error) {
        console.error(error);
    } finally {
        isLoading.value = false;
    }
};

const loadBreakdown = async () => {
    loading.value = true;
    response.value = await GetContentsClaimStatusList();
    loading.value = false;
};

const getCount = (id) => {
    const item = response.value.find(counter => counter.id === id);
    return item ? formatNumberWithCommas(item.count) : 0;
};

function formatNumberWithCommas(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const activeClaimsCount = computed(() => {
    const activeClaim = response.value.find(counter => counter.id === 1);
    return activeClaim ? formatNumberWithCommas(activeClaim.count) : 0;
})

const getTotalRevenue = async () => {
    const response = await GetRevenueWidgets("RevenueTotal");
    return formatNumberWithCommas(response);
}

const getRevenueLastMonth = async () => {
    const response = await GetRevenueWidgets("RevenueMonth");
    return formatNumberWithCommas(response);
}

const getVideoTutorialList = async () => {
    try {
        const response = await GetVideoTutorialList(1);
        return response.data.find(item => !item.isDeleted);
    } catch (error) {
        console.error("Error fetching tutorials:", error);
    }
};
const getClaimStatusOverview = async () => {
    try {
        const response = await GetContentsClaimStatusOverview();
        return response;
    } catch (error) {
        console.error("Error fetching tutorials:", error);
    }
};

const pendingClaims = computed(() => {
    const claim = claimStatusOverview.value.find(status => status.name === 'Pending');
    return formatNumberWithCommas(claim.value)
})
const approvalRatePercentage = computed(() => {
    const claim = claimStatusOverview.value.find(status => status.name === 'ApprovalRatePercentage');
    return claim.value
})

const getYoutubeVideoIframe = (videoLink) => {
    if (!videoLink) {
        return '';
    }

    const videoId = extractVideoId(videoLink);
    if (!videoId) {
        console.error("Could not extract video ID from link:");
        return '';
    }

    return `https://www.youtube.com/embed/${videoId}?rel=0&color=white&disablekb=1&modestbranding=1&cc_load_policy=1`;
};

defineExpose({ loadBreakdown });

const googleSheetAlert = useStorage('googleSheetAlert', true);
const revenueAlert = useStorage('revenueAlert', true);

useEventListener(window, 'reset-alerts', () => {
    googleSheetAlert.value = null;
    revenueAlert.value = null;
});

</script>

<style scoped>
.metrics-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}

.metrics-container .card {
    flex: 1 1 calc(33.33% - 1rem);
}

@media (min-width: 768px) and (max-width: 1023px) {
    .metrics-container .card {
        flex: 1 1 calc(50% - 1rem);
    }

    .metrics-container .card:nth-child(3) {
        flex: 1 1 100%;
        justify-content: center;
        display: flex;
    }
}

/* Mobile: 1 card per row */
@media (max-width: 767px) {
    .metrics-container .card {
        flex: 1 1 100%;
    }
}

.btn-primary {
    background-color: var(--primary);
    display: flex;
    align-items: center;
    gap: 5px;
}

.badge.bg-primary {
    background-color: var(--primary) !important;
}

.spinner-grow {
    color: var(--accent);
}

.card {
    border-radius: 16px;
}

.card-header {
    color: black !important;
    font-family: 'GilroyBold', sans-serif;
    background-color: white;
}

.card-title {
    color: var(--primary);
}

.alert-message {
    width: 100%;
}

@media (max-width: 767px) {
    .counter {
        min-height: 80px;
        width: 100%;
    }

    .alert-message {
        width: 75%;
    }
}

.overlay {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background: rgb(0 0 0 / 25%);
    z-index: 1;
}

.overlay .btn {
    background-color: var(--primary);
    color: white;
}

.counter:hover .card-body .overlay {
    display: grid;
    place-content: center;
}

td {
    text-wrap: nowrap;
    width: fit-content;
    text-align: left;
    font-size: 14px;
}

th {
    text-wrap: nowrap;
    width: fit-content;
    text-align: left;
    font-size: 14.5px;
}

.image-container {
    position: relative;
    display: inline-block;
}

.image-container .loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 35px;
    height: 35px;
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top-color: rgb(153, 153, 153);
    border-radius: 50%;
    animation: BorderSpin 1s linear infinite;
}

.image-container img {
    display: block;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.image-container-big .loader,
.image-container img {
    width: 135px;
    height: 135px;
}

.alert-container {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 14px;
}

@keyframes BorderSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>
