import { HttpClient } from "../HttpClient";
/**
 * * Get Contents Claim Status Overview
 */
export const GetContentsClaimStatusOverview = async () => {
  try {
    const { data } = await HttpClient.get("api/contents/ClaimStatusOverview");
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};
