import { HttpClient } from "../HttpClient";
/**
 * * Get Contents
 */
export const GetContentsList = async ({
  searchString,
  userId,
  claimStatus,
  sortColumn = "id",
  pageNumber = 1,
  rowsPerPage,
  assetLabel,
}) => {
  try {
    const { data } = await HttpClient.get("api/contents/list", {
      params: {
        ...(userId && { userId }),
        pageNumber,
        rowsPerPage,
        assetLabel,
        sortOrder: "desc",
        ...(claimStatus !== undefined ? { claimStatus } : {}),
        ...(searchString && { searchString }),
        ...(sortColumn && { sortColumn }),
      },
    });
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
};
