import { HttpClient } from "../HttpClient";

export const GetVideoTutorialList = async (pageNumber = 1) => {
  try {
    const { data } = await HttpClient.get(`api/Tutorials/List`, {
      params: { pageNumber },
    });
    return await Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
