import axios from "axios";

const apiKey = "AIzaSyAHfRRvUqf-L2nT4nFFRVc1OyneC4D3-nE"; // To be moved to environment variable

export const isYouTubeUrl = (url) => {
  const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
  return youtubePattern.test(url);
};

export const extractVideoId = (url) => {
  try {
    const urlObj = new URL(url);
    const hostname = urlObj.hostname.toLowerCase();

    if (hostname === "youtu.be") {
      return urlObj.pathname.substring(1);
    }

    if (hostname === "youtube.com" || hostname === "www.youtube.com") {
      const videoId = urlObj.searchParams.get("v");
      if (videoId) return videoId;
    }
  } catch (error) {
    const videoIdPattern =
      // eslint-disable-next-line no-useless-escape
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(videoIdPattern);
    return match ? match[1] : null;
  }

  // Return null if no method succeeds
  return null;
};

export const fetchVideoDetails = async (videoUrl) => {
  if (!isYouTubeUrl(videoUrl)) {
    console.error("Invalid YouTube URL");
    return;
  }
  const videoId = extractVideoId(videoUrl);
  if (!videoId) {
    console.error("Invalid YouTube URL");
    return false;
  }
  const url = `https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails,statistics&id=${videoId}&key=${apiKey}`;

  try {
    const response = await axios.get(url);
    const videoDetails = response.data.items[0];

    const title = videoDetails.snippet.title;
    const channelTitle = videoDetails.snippet.channelTitle;
    const channelId = videoDetails.snippet.channelId;
    const thumbnailUrl = videoDetails.snippet.thumbnails.high.url;
    const thumbnailWidth = videoDetails.snippet.thumbnails.high.width;
    const duration = convertYTVideoDuration(
      videoDetails.contentDetails.duration
    );
    const viewCount = formatCount(videoDetails.statistics.viewCount);

    return await Promise.resolve({
      videoId,
      title,
      channelTitle,
      channelId,
      thumbnailUrl,
      thumbnailWidth,
      duration,
      viewCount,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

export const fetchChannelDetails = async (channelId) => {
  const url = `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${channelId}&key=${apiKey}`;

  try {
    const response = await axios.get(url);
    const channelDetails = response.data.items[0];
    const id = channelDetails.id;
    const title = channelDetails.snippet.title;
    const thumbnail = channelDetails.snippet.thumbnails.default.url;
    const subscriberCount = formatCount(
      channelDetails.statistics.subscriberCount
    );

    return await Promise.resolve({
      id,
      title,
      thumbnail,
      subscriberCount,
    });
  } catch (err) {
    return Promise.reject(err);
  }
};

const formatCount = (subscriberCount) => {
  if (subscriberCount >= 1000000) {
    return (subscriberCount / 1000000).toFixed(1) + "M";
  } else if (subscriberCount >= 1000) {
    return (subscriberCount / 1000).toFixed(1) + "K";
  } else {
    return subscriberCount.toString();
  }
};

const convertYTVideoDuration = (duration) => {
  let hours = 0,
    minutes = 0,
    seconds = 0;

  const hoursMatch = duration.match(/(\d+)H/);
  const minutesMatch = duration.match(/(\d+)M/);
  const secondsMatch = duration.match(/(\d+)S/);

  if (hoursMatch) hours = parseInt(hoursMatch[1]);
  if (minutesMatch) minutes = parseInt(minutesMatch[1]);
  if (secondsMatch) seconds = parseInt(secondsMatch[1]);

  const pad = (num) => num.toString().padStart(2, "0");

  let formattedDuration;
  if (hours > 0) {
    formattedDuration = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  } else if (minutes > 0) {
    formattedDuration = `${pad(minutes)}:${pad(seconds)}`;
  } else {
    formattedDuration = `${seconds}`;
  }

  return formattedDuration;
};

export const fetchChannelIdFromUrl = async (youtubeChannelUrl) => {
  const url = `https://www.googleapis.com/youtube/v3/search?part=snippet&q=${youtubeChannelUrl}&type=channel&key=${apiKey}`;

  try {
    const response = await axios.get(url);
    const channel = response.data.items[0];

    if (channel) {
      return channel.id.channelId;
    } else {
      console.error("Channel not found for the provided username.");
      return null;
    }
  } catch (error) {
    console.error("Error fetching channel ID:", error);
    return [];
  }
};

export const fetchChannelDetailsDirectly = async (youtubeChannelUrl) => {
  const channelId = await fetchChannelIdFromUrl(youtubeChannelUrl);

  if (!channelId) {
    console.error("Channel ID could not be found.");
    return null;
  }

  const url = `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${channelId}&key=${apiKey}`;

  try {
    const response = await axios.get(url);
    const channelData = response.data.items[0];

    const channelDetails = {
      channelId: channelData.id,
      channelName: channelData.snippet.title,
      thumbnail: channelData.snippet.thumbnails.default.url,
      subscriberCount: formatCount(channelData.statistics.subscriberCount),
      channelUsername: channelData.snippet.customUrl,
    };

    return channelDetails;
  } catch (error) {
    console.error("Error fetching channel details:", error);
    throw error;
  }
};

export const fetchChannelDetailsBatch = async (channelIds) => {
  const url = `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${channelIds.join(
    ","
  )}&key=${apiKey}`;

  try {
    const response = await axios.get(url);
    if (!response.data.items || response.data.items.length === 0) {
      return [];
    }
    return response.data.items.map((channel) => ({
      channelId: channel.id,
      channelName: channel.snippet.title,
      thumbnail: channel.snippet.thumbnails.default.url,
      subscriberCount: formatCount(channel.statistics.subscriberCount),
      channelUsername: channel.snippet.customUrl,
    }));
  } catch (error) {
    console.error("Error in batch fetching channel details:", error);
    return [];
  }
};
